<template>
  <div>
    <hedaer @setSelect="setItem"></hedaer>
    <div
      class="responsibility item"
      :class="{ 'responsibility-bg': selectBab == 1 || selectBab == 2 }"
    >
      <ul
        class="home-tab wow fadeInUp"
        data-wow-delay="0.3s"
        style="padding-left: 0; padding-right: 0"
      >
        <li :class="{ selectBab: selectBab == 1 }" @click="setTab(1)">
          疫情公益
        </li>
        <li :class="{ selectBab: selectBab == 2 }" @click="setTab(2, 5, 1)">
          爱心助学
        </li>
        <li :class="{ selectBab: selectBab == 3 }" @click="setTab(3, 6, 2)">
          致敬奋斗者
        </li>
        <li :class="{ selectBab: selectBab == 4 }" @click="setTab(4, 3, 3)">
          书香碧莲盛
        </li>
      </ul>
      <div class="home-line wow fadeInUp" data-wow-delay="0.3s"></div>
      <div v-show="selectBab == 1" class="Epidemic">
        <div class="Profile-title wow fadeInUp" data-wow-delay="0.4s">
          疫情公益
        </div>
        <div class="Profile-en wow fadeInUp" data-wow-delay="0.5s">
          EPIDEMIC PUBLIC WELFARE
        </div>
        <img
          src="https://static.drlianzhuren.com/brandWeb//home/milepost/wh.jpg"
          alt=""
          class="Epidemic-img wow fadeInUp"
          data-wow-delay="0.6s"
        />
        <div class="Epidemic-item">
          <div class="Epidemic-title wow fadeInUp" data-wow-delay="0.4s">
            武汉疫情
          </div>
          <div class="Epidemic-text wow fadeInUp" data-wow-delay="0.3s">
            武汉新冠疫情爆发后，成立100万新型冠状病毒肺炎专项基金，用于采购专用医疗设备、口罩、防护服等，并分批赠送疫情前线。
          </div>
        </div>
        <img
          src="https://static.drlianzhuren.com/brandWeb//home/milepost/hb.jpg"
          alt=""
          class="Epidemic-img wow fadeInUp"
        />
        <div class="Epidemic-item">
          <div class="Epidemic-title wow fadeInUp" data-wow-delay="0.4s">
            河北疫情
          </div>
          <div class="Epidemic-text wow fadeInUp" data-wow-delay="0.3s">
            河北新冠疫情爆发后，迅速采购专业防疫物质，驰援河北疫情前线。
          </div>
        </div>
        <img
          src="https://static.drlianzhuren.com/brandWeb//home/milepost/hs.jpg"
          alt=""
          class="Epidemic-img wow fadeInUp"
        />
        <div class="Epidemic-item" style="margin-bottom: 0">
          <div class="Epidemic-title wow fadeInUp" data-wow-delay="0.4s">
            支援核酸
          </div>
          <div class="Epidemic-text wow fadeInUp" data-wow-delay="0.3s">
            2022年，碧莲盛闻令而动，24城分院积极支援抗疫，先后有218人次医护力量支援抗疫，覆盖核酸检测超过2万人次。
          </div>
        </div>
      </div>
      <div v-if="selectBab == 2" class="LoveHelpStudents">
        <div class="Profile-title seletlove">爱心助学</div>
        <div class="Profile-en seletlove">CARING STUDENTS</div>
        <p class="LoveHelpStudents-text seletlove">
          2021年，携手鸿基金启动山西公益行，为山西河津市上市小学捐赠“爱的图书馆”。
        </p>
        <p class="LoveHelpStudents-text seletlove">
          2022年，发起了六一爱心助学公益行活动，为山西河津市上市小学捐赠爱心助学物质。
        </p>
        <div class="seletlove">
          <swiper ref="mySwiper1" :options="swiperOptions" key="2">
            <swiper-slide v-for="item in 5" :key="item">
              <img
                class="LoveHelpStudents-img"
                :src="`${$url}/responsibility/love${item}.jpg`"
                alt=""
            /></swiper-slide>
          </swiper>
        </div>
        <div class="LoveHelpStudents-item">
          <div
            :class="{ 'select-LoveHelpStudents': isMilepost == 1 }"
            @click="isMilepost = 1"
          ></div>
          <div
            :class="{ 'select-LoveHelpStudents': isMilepost == 2 }"
            @click="isMilepost = 2"
          ></div>
          <div
            :class="{ 'select-LoveHelpStudents': isMilepost == 3 }"
            @click="isMilepost = 3"
          ></div>
          <div
            :class="{ 'select-LoveHelpStudents': isMilepost == 4 }"
            @click="isMilepost = 4"
          ></div>
          <div
            :class="{ 'select-LoveHelpStudents': isMilepost == 5 }"
            @click="isMilepost = 5"
          ></div>
        </div>
      </div>
      <div v-if="selectBab == 3" class="LoveHelpStudents">
        <div class="Profile-title seletlove">致敬奋斗者</div>
        <div class="Profile-en seletlove">PAY TRIBUTE TO THE STRIVER</div>
        <p class="LoveHelpStudents-text seletlove">
          互联网时代，工作压力大，很多年轻人在努力奋斗的时候，头顶一不小心就成了“地中海”。碧莲盛发起的“致敬奋斗者”系列公益行动，为企业员工提供毛发健康义诊，帮助他们全面了解自己的头发健康，增强毛发健康管理意识，树立健康生活理念。
        </p>
        <div class="seletlove">
          <swiper ref="mySwiper2" :options="swiperOptions" key="3">
            <swiper-slide v-for="item in 6" :key="item">
              <img
                class="LoveHelpStudents-img"
                :src="`${$url}/responsibility/Striver${item}.jpg`"
                alt=""
            /></swiper-slide>
          </swiper>
        </div>
        <div class="LoveHelpStudents-item">
          <div
            :class="{ 'select-LoveHelpStudents': isMilepost == 1 }"
            @click="isMilepost = 1"
          ></div>
          <div
            :class="{ 'select-LoveHelpStudents': isMilepost == 2 }"
            @click="isMilepost = 2"
          ></div>
          <div
            :class="{ 'select-LoveHelpStudents': isMilepost == 3 }"
            @click="isMilepost = 3"
          ></div>
          <div
            :class="{ 'select-LoveHelpStudents': isMilepost == 4 }"
            @click="isMilepost = 4"
          ></div>
          <div
            :class="{ 'select-LoveHelpStudents': isMilepost == 5 }"
            @click="isMilepost = 5"
          ></div>
          <div
            :class="{ 'select-LoveHelpStudents': isMilepost == 6 }"
            @click="isMilepost = 6"
          ></div>
        </div>
      </div>
      <div v-if="selectBab == 4" class="LoveHelpStudents">
        <div class="Profile-title seletlove">书香碧莲盛</div>
        <div class="Profile-en seletlove">SCHOLARLY LOTUS</div>
        <p class="LoveHelpStudents-text seletlove">
          碧莲盛携手有书开启员工成长计划，共同开启员工共读模式，带领每一位员工一起共读书、读好书，让阅读成为一种生活态度和生活方式。
        </p>
        <p class="LoveHelpStudents-text seletlove">
          碧莲盛将同有书一起积极推动书香企业建设，一起广泛地组织全员读书，将国家倡导的全民阅读活动真正扎实落地，并由企业全员阅读带动影响家庭阅读、社会阅读，以此为建设书香中国贡献企业力量。
        </p>
        <div class="seletlove">
          <swiper ref="mySwiper3" :options="swiperOptions" key="4">
            <swiper-slide v-for="item in 3" :key="item">
              <img
                class="LoveHelpStudents-img"
                :src="`${$url}/responsibility/ScholarlyFragrance${item}.jpg`"
                alt=""
            /></swiper-slide>
          </swiper>
        </div>
        <div class="LoveHelpStudents-item">
          <div
            :class="{ 'select-LoveHelpStudents': isMilepost == 1 }"
            @click="isMilepost = 1"
          ></div>
          <div
            :class="{ 'select-LoveHelpStudents': isMilepost == 2 }"
            @click="isMilepost = 2"
          ></div>
          <div
            :class="{ 'select-LoveHelpStudents': isMilepost == 3 }"
            @click="isMilepost = 3"
          ></div>
        </div>
      </div>
    </div>
    <Footer @setItem="setItem"></Footer>
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  data () {
    const that = this
    return {
      index: 1,
      max: 6,
      timer: null,
      Milepost: null,
      selectBab: 1,
      isMilepost: 1,
      swiperOptions: {
        direction: 'horizontal',
        loop: true,
        on: {
          slideChangeTransitionStart: function () {
            that.isMilepost = this.realIndex + 1
          }
        },
        slidesPerView: 1,
        autoplay: {
          disableOnInteraction: false,
          delay: 2000 // 1秒切换一次
        },

        autoplayDisableOnInteraction: false
      }
    }
  },
  components: {
    swiper,
    swiperSlide
  },
  methods: {
    setTab (a, b, c) {
      if (a !== 1) {
        this.index = c
        this.max = b
      }
      this.selectBab = a
    },
    setItem (a) {
      this.selectBab = a
    }
  },
  mounted () {
    if (this.$route.params.id) {
      this.selectBab = this.$route.params.id + 1
    }
  }
}
</script>

<style lang="scss">
.Epidemic {
  .Epidemic-img {
    width: 500px;
    height: 284px;
    vertical-align: middle;
  }
  .Epidemic-item {
    width: 500px;
    height: 200px;
    background-color: #ffffff;
    padding: 32px 24px 0 24px;
    margin-bottom: 25px;
    .Epidemic-title {
      font-size: 25px;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #363636;
      margin-bottom: 18px;
    }
    .Epidemic-text {
      font-size: 19px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 30px;
      letter-spacing: 0px;
      color: #707070;
    }
  }
}
.responsibility-bg {
  background: #f9fafb;
}
.LoveHelpStudents {
  .LoveHelpStudents-item {
    display: flex;
    justify-content: center;
    margin-top: 35px;
    div {
      width: 50px;
      height: 3px;
      background-color: #cfcfcf;
      margin-right: 9px;
    }
    .select-LoveHelpStudents {
      background-color: #2e59a7;
    }
    div:last-child {
      margin-right: 0;
    }
  }
}

.swiper {
  width: 100%;
  margin-bottom: 20px;

  .swiper-slide {
    width: 335px !important;
    height: 120px;
    font-size: 14px;
    text-align: center;
    line-height: 80px;
    border-radius: 8px;
    position: relative;
  }

  .bookImg {
    width: 335px !important;
    height: 120px;
    border-radius: 8px;
  }
}

@-webkit-keyframes seletlove {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes seletlove {
  0% {
    opacity: 0;
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.seletlove {
  animation: seletlove 1s linear both;
}
</style>
